<template>
    <div>
        <v-toolbar tile flat>
            <v-toolbar-title>
                SIPIS - <small class="font-italic">Read only</small>
            </v-toolbar-title>
            <v-spacer/>
            <v-btn fab small color="success" elevation="0" disabled><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <div style="height: 70vh; overflow-y: scroll" class="pr-3">
            <div
                    v-for="(option, index) of options"
                    :key="index"
            >
                <v-card outlined class="pa-3 mb-4">
                    <h4 class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
                    <v-text-field
                            :placeholder="option.option"
                            filled
                            rounded
                            dense
                            hide-details
                            class="mb-2"
                            v-model="option.model"
                            readonly
                    />
                    <div class="pl-3">
                        <div class="font-weight-light">{{option.hint}}</div>
                        <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
                        <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
                        <div v-if="option.validValues">
                            <span class="blue-grey--text">Vaild Values:</span>
                            <v-chip
                                    v-for="value of option.validValues"
                                    :key="value"
                                    color="primary"
                                    class="mx-1"
                                    small
                            >{{value}}</v-chip>
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    import AcrobitOption from "../../../../classes/AcrobitOption";
    export default {
        name: "sipis",
        data() {
            return{
                options: [
                    new AcrobitOption({
                        option: 'sipisHost',
                        default: 'sipis.acrobits.cz',
                        validValues: ['domain name or IP address'],
                        description: 'Specifies the SIPIS server which the app should connect to.',
                        model: 'sipis.acrobits.cz',
                        example: 'sipis.example.com'
                    }),
                    new AcrobitOption({
                        option: 'sipisRegServer',
                        default: 'reg.acrobits.cz',
                        validValues: ['domain name or IP address'],
                        description: 'Specifies the server which the app will use to transfer the account credentials ' +
                            'to SIPIS. Typically it will be the same address as in sipisHost',
                        model: 'reg.acrobits.cz',
                        example: 'sipis.example.com'
                    }),
                    new AcrobitOption({
                        option: 'sipisHostPrefixLength',
                        default: '1',
                        validValues: ['integer values larger than 0'],
                        description: 'Used for load-balancing of SIPIS servers. In case it’s greater than zero, ' +
                            'the actual sipisHost value is constructed by prefixing s-X. to address in sipisHost. ' +
                            'The X is taken from account selector, which is SHA1(username:password:host). The value ' +
                            'in sipisHostPrefixLength specifies how many letters from the hex-digit representation of' +
                            ' selector to use. For example, with sipisHostPrefixLength=1, the app will use servers ' +
                            's-0.sipis.example.com - s-F.sipis.example.com. You will need to set up the DNS and deploy' +
                            ' multiple SIPIS servers to make load balancing work.',
                        model: '1',
                        example: '0'
                    })
                ],
            }
        }
    }
</script>

<style scoped>

</style>